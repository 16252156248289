
.aj-page {
  .bg1 {
    background: #eff4f8;
  }

  .bg2 {
    background: #fff;
  }

  .com-title1 {
    height: 35px;
    line-height: 35px;
    position: relative;
    display: flex;
    justify-content: center;

    span {
      display: inline-block;
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      background: #eff4f8;
      padding: 0 13px;
      position: relative;
    }

    span::before {
      content: '';
      width: 6px;
      height: 20px;
      background: #c30d23;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    span::after {
      content: '';
      width: 6px;
      height: 20px;
      background: #c30d23;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .com-title1::before {
    content: '';
    width: 100%;
    height: 1px;
    background: #c30d23;
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .com-title2 {
    display: flex;
    justify-content: space-between;
    background: #f5fbff;
    height: 44px;
    padding-left: 13px;
    padding-right: 10px;

    h5 {
      font-weight: 500;
      font-size: 22px;
      color: #2b221b;
      line-height: 44px;
      padding-left: 16px;
      position: relative;
    }

    h5::before {
      content: '';
      width: 6px;
      height: 18px;
      background: #c30d23;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    div {
      display: flex;
      align-items: center;

      ul {
        display: flex;

        li {
          line-height: 44px;
          color: #0d1020;
          font-size: 16px;
          font-weight: bold;
          padding: 0 20px;
          position: relative;
          cursor: pointer;
        }

        li::after {
          content: '';
          width: 1px;
          height: 11px;
          background: #777777;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        li:last-child::after {
          display: none;
        }

        li.active {
          color: #c30d23;
        }
      }

      img.more {
        display: inline-block;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      a {
        cursor: pointer;
      }
    }
  }

  ul.com-book {
    position: relative;
    margin-top: 40px;
    z-index: 0;
    display: flex;
    min-height: 366px;
    //flex-wrap: wrap;
    li {
      margin-right: 24px;
      width: 180px;
      margin-bottom: 15px;

      img {
        width: 180px;
        height: 260px;
        margin-bottom: 75px;
        cursor: pointer;
        box-shadow: 0px 15px 18px -15px #4f4a4a;
      }

      p {
        color: #0d1020;
        font-size: 16px;
        width: 180px;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }

  ul.com-book::after {
    content: '';
    position: absolute;
    width: 1380px;
    height: 116px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: -2;
    background: url(../assets/img/oa/img-bookshelf.png);
    background-size: 100% 100%;
  }

  .citation {
    padding-top: 60px;
    padding-bottom: 50px;

    .type-list {
      background: #fff;
      padding: 25px 10px 16px;
      display: flex;
      justify-content: space-between;

      ul {
        display: flex;

        li {
          padding: 0 12px;
          height: 32px;
          line-height: 32px;
          background: #f8fafb;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          margin-right: 24px;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          border: 1px solid #dee6e9;
          cursor: pointer;
        }

        li.active {
          background: #c30d23;
          color: #fff;
        }
      }

      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
        margin-top: 2px;
      }
    }


    .swiper-book .card-carousel-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0 20px;
      color: #666a73;
    }

    .swiper-book .card-carousel {
      display: flex;
      justify-content: center;
      // width: 958px;
      width: 1078px; //708px;
    }

    .swiper-book .card-carousel--overflow-container {
      overflow: hidden;
    }

    .swiper-book .card-carousel--nav__left,
    .card-carousel--nav__right {
      display: inline-block;
      width: 30px;
      height: 30px;
      padding: 10px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-top: 4px solid #bd1a2d;
      border-right: 4px solid #bd1a2d;
      cursor: pointer;
      margin: 0px 12px;
      transition: transform 150ms linear;
    }

    .swiper-book .card-carousel--nav__left[disabled],
    .card-carousel--nav__right[disabled] {
      opacity: 0.2;
      border-color: black;
    }

    .swiper-book .card-carousel--nav__left {
      transform: rotate(-135deg);
    }

    .swiper-book .card-carousel--nav__left:active {
      transform: rotate(-135deg) scale(0.9);
    }

    .swiper-book .card-carousel--nav__right {
      transform: rotate(45deg);
    }

    .swiper-book .card-carousel--nav__right:active {
      transform: rotate(45deg) scale(0.9);
    }

    .swiper-book .card-carousel-cards {
      display: flex;
      transition: transform 150ms ease-out;
      transform: translatex(0px);
    }

    .swiper-book .card-carousel-cards .card-carousel--card {
      margin: 0 16px;
      cursor: pointer;
      //background-color: #fff;
      z-index: 3;
      margin-bottom: 2px;
      width: 153px;
      height: 254px; //222px;
    }

    .swiper-book .card-carousel-cards .card-carousel--card:first-child {
      margin-left: 0;
    }

    .swiper-book .card-carousel-cards .card-carousel--card:last-child {
      margin-right: 0;
    }

    .swiper-book .card-carousel-cards .card-carousel--card img {
      vertical-align: bottom;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      // border-radius: 4px;
      transition: opacity 150ms linear;
      user-select: none;
      // width: 153px;
      // border: 1px  solid rgba(0, 0, 0, 0.1);
      width: 153px;
      height: 222px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 6px 1px rgba(15, 11, 5, 0.2);
    }

    .swiper-book .card-carousel-cards .card-carousel--card .title-spell {
      color: #0d1020;
      font-size: 16px;
      display: block;
      width: 153px;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .swiper-book .card-carousel-cards .card-carousel--card img:hover {
      opacity: 0.5;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer {
      border-top: 0;
      padding: 7px 15px;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer p {
      padding: 3px 0;
      margin: 0;
      margin-bottom: 2px;
      font-size: 19px;
      font-weight: 500;
      color: #2c3e50;
      user-select: none;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2) {
      font-size: 12px;
      font-weight: 300;
      padding: 6px;
      background: rgba(40, 44, 53, 0.06);
      display: inline-block;
      position: relative;
      margin-left: 4px;
      color: #666a73;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):before {
      content: '';
      float: left;
      position: absolute;
      top: 0;
      left: -12px;
      width: 0;
      height: 0;
      border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
      border-style: solid;
      border-width: 12px 12px 12px 0;
    }

    .swiper-book .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):after {
      content: '';
      position: absolute;
      top: 10px;
      left: -1px;
      float: left;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: white;
      box-shadow: -0px -0px 0px #004977;
    }
  }

  .subject {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .organizer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.pagination-wrap {
  display: flex;
  align-items: center;

  .total-page {
    font-size: 14px;
    color: #333 !important;
    margin-right: 4px;
  }
}
